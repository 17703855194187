<template>
  <div class="row">
    <div class="col-12">
      <div class="card concard card-shadow">
        <div class="card-header text-end">
          <div>
            <i @click="edit" class="bi bi-gear icon"></i>

            <img class="icon-logo" src="@/assets/logo.png" alt="..." />
          </div>
          <div class="row mt-3" style="color: white">
            <div class="col-5" style="padding: 0">
              <div class="text-center">
                <img :src="ProfireLine.pictureUrl" alt="image" class="img-profile" />
              </div>
            </div>
            <div class="col-7 text-start" style="padding: 0">
              <div class="row">
                <span style="font-size: 20px">{{
                  ProfireLine.displayName
                }}</span>
                <span class="badge badge-success mt-1 sy">Siam Inno City</span>
                <span style="font-size: 12px" class="mt-1">{{
                  ProfireLine.userId
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="text-start mn-profile">
          <span class="text-root mini-h">จัดการโปรไฟล์</span>
        </div>

        <div class="card-body mt-2 white">
          <div class="title text-end">
            <i style="
                font-size: 20px;
                color: steelblue;
                float: left;
                margin-top: -5px;
              " class="bi bi-person-circle"><span
                style="font-size: 14px; margin-left: 5px; color: black">ข้อมูลส่วนตัว</span></i>
            <span @click="edit" class="title-text">แก้ไขโปรไฟล์</span><i class="bi bi-arrow-bar-right"></i>
          </div>
          <div class="body-text mb-47">
            <!--begin::Row-->
            <div class="row mb-7 mt-2">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">ชื่อ</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark"><span v-if="Profire.fn_other">{{ Profire.fn_other }}</span>
                  <span v-else>{{ Profire.fn }}</span> {{ Profire.firstname }}
                  {{ Profire.lastname }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">เพศ</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">{{
                  Profire.gender
                }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">เบอร์โทรศัพท์</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">{{ Profire.tel }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            <br />
            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-12 fw-bold text-muted">ที่อยู่อาศัย</label>
              <!--end::Label-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">บ้านเลขที่</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">{{
                  Profire.numberhome
                }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">หมู่</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span v-if="Profire.moo" class="fw-bolder fs-6 text-dark">{{
                  Profire.moo
                }}</span>
                <span v-else class="fw-bolder fs-6 text-dark">-</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">ซอย</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span v-if="Profire.alleyway" class="fw-bolder fs-6 text-dark">{{ Profire.alleyway }}</span>
                <span v-else class="fw-bolder fs-6 text-dark">-</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">ถนน</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span v-if="Profire.road" class="fw-bolder fs-6 text-dark">{{
                  Profire.road
                }}</span>
                <span v-else class="fw-bolder fs-6 text-dark">-</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">ตำบล</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">{{
                  Profire.district
                }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">อำเภอ</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">{{
                  Profire.amphoe
                }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">จังหวัด</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">{{
                  Profire.province
                }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">รหัสไปรษณีย์</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">{{
                  Profire.zipcode
                }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
          </div>
        </div>

        <div class="card-footer white">
          <div class="d-flex justify-center align-center">
            <button @click="close" class="button">ปิดหน้านี้</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import liff from "@line/liff";
export default defineComponent({
  name: "Profile",
  setup() {
    const store = inject("store");
    const router = inject("router");

    const Profire = store.getters.getProfire;
    const ProfireLine = store.getters.getProfireLine;

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const close = () => {
      liff.ready.then(() => {
        liff.closeWindow();
      });
    };

    const edit = () => {
      store.dispatch(SET_REGISTER, Profire);
      router.push({ name: "Edit" });
    };

    return {
      edit,
      close,
      Profire,
      ProfireLine,
    };
  },
});
</script>

<style scoped>
.card-header {
  height: 160px;
  min-height: 160px;
  background-color: var(--color);
}

.mini-h {
  font-size: 20px;
  margin-left: 5px;
}

.icon {
  font-size: 1.5rem;
  color: var(--color);
}

.sy {
  background-color: #daa315;
  width: 100px;
  margin-left: 10px;
}

.img-profile {
  width: 80px;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
}

.card-body {
  padding: 0;
}

.body-text {
  padding: 1rem 2rem !important;
}

.mn-profile {
  height: 50px;
  min-height: 50px;
  border-bottom: 2px solid rgb(1 150 194);
  padding: 10px;
  background-color: white;
}

.title {
  background-color: white;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px 10px;
}

.title-text {
  font-size: 12px;
  margin-right: 5px;
}

.icon-logo {
  height: 27px;
  background-color: var(--color);
  margin-left: 10px;
  border-radius: 50%;
  margin-top: -10px;
}
</style>
